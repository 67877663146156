// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div style="width: 100%;">
    <!-- confirmationDeleteModal -->
    <a-modal
      :title="$t('label.distributor.article.delete') + ' - ' + selectedToDeleteName"
      @ok="deleteArticle()"
      @cancel="closeAction"
      :okText="$t('label.delete')"
      cancelText="Cancel"
      v-model:visible="openDeleteArticleModal"
      :closable="true"
      centered>
      <DeleteOutlined :style="{fontSize: '40px', color: 'red'}" />
      <br/>
      <span>{{ $t('message.delete.article') }}</span>
      <br/>
      <span>{{ selectedToDeleteName }}</span>
    </a-modal>
    <!-- Add Article Modal -->
    <a-modal
      :title="$t('label.distributor.article.add')"
      @ok="submitAddArticle"
      @cancel="closeAction"
      okText="OK"
      cancelText="Cancel"
      :maskClosable="false"
      :width="1000"
      destroyOnClose
      v-model:visible="openAddArticleModal"
      :closable="true"
      centered>
      <a-form
        :ref="formRef"
        :model="form"
        :rules="rules"
        @finish="submitAddArticle"
        layout="vertical"
      >
        <a-spin :spinning="loading">
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item
                ref="articleNumber"
                name="articleNumber">
                <template #label>
                  <tooltip-label :title="$t('label.article.number')" :tooltip="$t('label.article.number.tooltip') + ' ' + $t('label.article.number') + $t('message.character.limit', [25])"/>
                </template>
                <a-input
                  v-model:value="form.articleNumber"
                  :placeholder="$t('label.article.number.tooltip')" />
              </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
              <a-form-item
                ref="description"
                name="description">
                <template #label>
                  <tooltip-label :title="$t('label.article.description')" :tooltip="$t('label.article.description.tooltip') + ' ' + $t('label.description') + $t('message.character.limit', [100])"/>
                </template>
                <a-input
                  v-model:value="form.description"
                  :placeholder="$t('label.article.description.tooltip')" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item
              ref="manufacturer"
              name="manufacturer">
              <template #label>
                <tooltip-label :title="$t('label.manufacturer')" :tooltip="$t('label.manufacturer.tooltip') + ' ' + $t('label.manufacturer') + $t('message.character.limit', [35])"/>
              </template>
              <a-input
                v-model:value="form.manufacturer"
                :placeholder="$t('label.manufacturer.tooltip')" />
            </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
              <a-form-item
                ref="manufacturerNumber"
                name="manufacturerNumber">
                <template #label>
                  <tooltip-label :title="$t('label.article.number') + ' (' + $t('label.manufacturer') +')'" :tooltip="$t('label.article.number.tooltip2') + ' ' + $t('label.article.number') + ' (' + $t('label.manufacturer') +')' + $t('message.character.limit', [50])"/>
                </template>
                <a-input
                  v-model:value="form.manufacturerNumber"
                  :placeholder="$t('label.article.number.tooltip2')" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item
            ref="productGroup"
            name="productGroup">
            <template #label>
              <tooltip-label :title="$t('label.productGroup')" :tooltip="$t('label.productGroup.tooltip') + ' ' + $t('label.productGroup') + $t('message.character.limit', [200])"/>
            </template>
            <a-input
              v-model:value="form.productGroup"
              :placeholder="$t('label.productGroup.tooltip')" />
          </a-form-item>
          <a-form-item
            ref="detailedDescription"
            name="detailedDescription">
            <template #label>
              <tooltip-label :title="$t('label.article.detailedDescription')" :tooltip="$t('label.article.detailedDescription.tooltip') + ' ' + $t('label.article.detailedDescription') + $t('message.character.limit', [250])"/>
            </template>
            <a-input
              v-model:value="form.detailedDescription"
              :placeholder="$t('label.article.detailedDescription.tooltip')" />
          </a-form-item>
          <a-form-item
            ref="technicalDescriptionUnstructured"
            name="technicalDescriptionUnstructured">
            <template #label>
              <tooltip-label :title="$t('label.technicalDescription.unstructured')" :tooltip="$t('label.technicalDescription.unstructured.tooltip') + ' ' + $t('label.technicalDescription.unstructured') + $t('message.character.limit', [2000])"/>
            </template>
            <a-input
              v-model:value="form.technicalDescriptionUnstructured"
              :placeholder="$t('label.technicalDescription.unstructured.tooltip')" />
          </a-form-item>
          <a-form-item
            ref="technicalDescriptionStructured"
            name="technicalDescriptionStructured">
            <template #label>
              <tooltip-label :title="$t('label.technicalDescription.structured')" :tooltip="$t('label.technicalDescription.structured.tooltip') + ' ' + $t('label.technicalDescription.structured') + $t('message.character.limit', [2000])"/>
            </template>
            <a-textarea
              :autosize="{ minRows:1, maxRows: 8}"
              v-model:value="form.technicalDescriptionStructured"
              :placeholder="$t('label.technicalDescription.structured.tooltip')" />
          </a-form-item>
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item
                ref="available"
                name="available">
                <template #label>
                  <tooltip-label :title="$t('label.available')" :tooltip="$t('label.available.tooltip') + ' ' + $t('label.available') + $t('message.character.limit', [12])"/>
                </template>
                <a-input-number
                  v-model:value="form.available"
                  style="width: 470px;"
                  :min="0"
                  :max="999999999999"
                  :placeholder="$t('label.available.tooltip')" />
              </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
              <a-form-item
                ref="price"
                name="price">
                <template #label>
                  <tooltip-label :title="$t('label.price') + ' [€]'" :tooltip="$t('label.price.tooltip') + ' ' + $t('label.price') + $t('message.character.limit', [12])"/>
                </template>
                <a-input-number
                  v-model:value="form.price"
                  style="width: 470px;"
                  :min="0"
                  :max="999999999999.99"
                  decimalSeparator=","
                  :placeholder="$t('label.price.tooltip')" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item
            ref="url"
            name="url">
            <template #label>
              <tooltip-label :title="$t('label.url')" :tooltip="$t('label.url.tooltip') + ' ' + $t('label.url') + $t('message.character.limit', [200])"/>
            </template>
            <a-input
              v-model:value="form.url"
              :placeholder="$t('label.url.tooltip')" />
          </a-form-item>
          <a-form-item
            ref="urlImage"
            name="urlImage">
            <template #label>
              <tooltip-label :title="$t('label.url.image')" :tooltip="$t('label.url.image.tooltip') + ' ' + $t('label.url.image') + $t('message.character.limit', [200])"/>
            </template>
            <a-input
              v-model:value="form.urlImage"
              :placeholder="$t('label.url.image.tooltip')" />
          </a-form-item>
          <div v-if="form.urlImage !== undefined && form.urlImage !== ''">
            <a-form-item
              ref="urlImage2"
              name="urlImage2">
              <template #label>
                <tooltip-label :title="$t('label.url.image2')" :tooltip="$t('label.url.image2.tooltip') + ' ' + $t('label.url.image2') + $t('message.character.limit', [200])"/>
              </template>
              <a-input
                @change="changeUrlImage2"
                v-model:value="form.urlImage2"
                :placeholder="$t('label.url.image2.tooltip')" />
            </a-form-item>
          </div>
          <div v-if="form.urlImage2 !== undefined && form.urlImage2 !== ''">
            <a-form-item
              ref="urlImage3"
              name="urlImage3">
              <template #label>
                <tooltip-label :title="$t('label.url.image3')" :tooltip="$t('label.url.image3.tooltip') + ' ' + $t('label.url.image3') + $t('message.character.limit', [200])"/>
              </template>
              <a-input
                v-model:value="form.urlImage3"
                :placeholder="$t('label.url.image3.tooltip')" />
            </a-form-item>
          </div>
          <a-form-item ref="selectDistributor" name="selectDistributor">
            <template #label>
                <tooltip-label :title="$t('label.select.distributor')" :tooltip="$t('message.select.distributor.article')"/>
            </template>
            <a-select v-model:value="form.selectDistributor" :placeholder="$t('message.select.distributor.article')">
              <a-select-option v-for="distributor in distributorsList" :key="distributor.Uuid">
                  <span>{{ distributor.Name }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-spin>
      </a-form>
    </a-modal>
    <!-- Edit Article Modal -->
    <a-modal
      :title="$t('label.distributor.article.edit')"
      @ok="submitEditArticle"
      @cancel="closeAction"
      okText="OK"
      destroyOnClose
      :maskClosable="false"
      cancelText="Cancel"
      :width="1000"
      v-model:visible="openEditArticleModal"
      :closable="true"
      centered>
      <a-form
        v-ctrl-enter="submitAddArticle"
        :ref="formRefEdit"
        :model="formEdit"
        :rules="rulesEdit"
        @finish="submitAddArticle"
        layout="vertical"
      >
      <a-spin :spinning="loading">
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item
            ref="articleNumberEdit"
            name="articleNumberEdit">
            <template #label>
              <tooltip-label :title="$t('label.article.number')" :tooltip="$t('label.article.number.tooltip') + ' ' + $t('label.article.number') + $t('message.character.limit', [25])"/>
            </template>
            <a-input
              v-model:value="formEdit.articleNumberEdit"
              style="margin-top: 5px;"
              :placeholder="$t('label.article.number.tooltip')" />
          </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item
              ref="descriptionEdit"
              name="descriptionEdit">
              <template #label>
                <tooltip-label :title="$t('label.article.description')" :tooltip="$t('label.article.description.tooltip') + ' ' + $t('label.article.description') + $t('message.character.limit', [100])"/>
              </template>
              <a-input
                v-model:value="formEdit.descriptionEdit"
                style="margin-top: 5px;"
                :placeholder="$t('label.article.description.tooltip')" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
          <a-form-item
            ref="manufacturerEdit"
            name="manufacturerEdit">
            <template #label>
              <tooltip-label :title="$t('label.manufacturer')" :tooltip="$t('label.manufacturer.tooltip') + ' ' + $t('label.manufacturer') + $t('message.character.limit', [35])"/>
            </template>
            <a-input
              v-model:value="formEdit.manufacturerEdit"
              style="margin-top: 5px;"
              :placeholder="$t('label.manufacturer.tooltip')" />
          </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item
              ref="manufacturerNumberEdit"
              name="manufacturerNumberEdit">
              <template #label>
                <tooltip-label :title="$t('label.article.number') + ' (' + $t('label.manufacturer') +')'" :tooltip="$t('label.article.number.tooltip2') + ' ' + $t('label.article.number') + ' (' + $t('label.manufacturer') +')' + $t('message.character.limit', [50])"/>
              </template>
              <a-input
                v-model:value="formEdit.manufacturerNumberEdit"
                style="margin-top: 5px;"
                :placeholder="$t('label.article.number.tooltip2')" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
            ref="productGroupEdit"
            name="productGroupEdit">
            <template #label>
              <tooltip-label :title="$t('label.productGroup')" :tooltip="$t('label.productGroup.tooltip') + ' ' + $t('label.productGroup') + $t('message.character.limit', [200])"/>
            </template>
            <a-input
              v-model:value="formEdit.productGroupEdit"
              style="margin-top: 5px;"
              :placeholder="$t('label.productGroup.tooltip')" />
          </a-form-item>
          <a-form-item
            ref="detailedDescriptionEdit"
            name="detailedDescriptionEdit">
            <template #label>
              <tooltip-label :title="$t('label.article.detailedDescription')" :tooltip="$t('label.article.detailedDescription.tooltip') + ' ' + $t('label.article.detailedDescription') + $t('message.character.limit', [250])"/>
            </template>
            <a-input
              v-model:value="formEdit.detailedDescriptionEdit"
              :placeholder="$t('label.article.detailedDescription.tooltip')" />
          </a-form-item>
          <a-form-item
            ref="technicalDescriptionUnstructuredEdit"
            name="technicalDescriptionUnstructuredEdit">
            <template #label>
              <tooltip-label :title="$t('label.technicalDescription.unstructured')" :tooltip="$t('label.technicalDescription.unstructured.tooltip') + ' ' + $t('label.technicalDescription.unstructured') + $t('message.character.limit', [2000])"/>
            </template>
            <a-input
              v-model:value="formEdit.technicalDescriptionUnstructuredEdit"
              :placeholder="$t('label.technicalDescription.unstructured.tooltip')" />
          </a-form-item>
          <a-form-item
            ref="technicalDescriptionStructuredEdit"
            name="technicalDescriptionStructuredEdit">
            <template #label>
              <tooltip-label :title="$t('label.technicalDescription.structured')" :tooltip="$t('label.technicalDescription.structured.tooltip') + ' ' + $t('label.technicalDescription.structured') + $t('message.character.limit', [2000])"/>
            </template>
            <a-textarea
              :autosize="{ minRows:2, maxRows: 8}"
              v-model:value="formEdit.technicalDescriptionStructuredEdit"
              :placeholder="$t('label.technicalDescription.structured.tooltip')" />
          </a-form-item>
          <div style="display: flex; justify-content: space-between;">
            <a-form-item
            ref="availableEdit"
            name="availableEdit">
            <template #label>
              <tooltip-label :title="$t('label.available')" :tooltip="$t('label.available.tooltip') + ' ' + $t('label.available') + $t('message.character.limit', [12])"/>
            </template>
            <a-input-number
              v-model:value="formEdit.availableEdit"
              style="width: 470px;"
              :min="0"
              :max="999999999999"
              :placeholder="$t('label.available.tooltip')" />
          </a-form-item>
          <a-form-item
            ref="priceEdit"
            name="priceEdit">
            <template #label>
              <tooltip-label :title="$t('label.price') + ' [€]'" :tooltip="$t('label.price.tooltip') + ' ' + $t('label.price') + $t('message.character.limit', [12])"/>
            </template>
            <a-input-number
              v-model:value="formEdit.priceEdit"
              style="width: 470px;"
              :min="0"
              :max="999999999999.99"
              decimalSeparator=","
              :placeholder="$t('label.price.tooltip')" />
          </a-form-item>
          </div>
          <a-form-item
            ref="urlEdit"
            name="urlEdit">
            <template #label>
              <tooltip-label :title="$t('label.url')" :tooltip="$t('label.url.tooltip') + ' ' + $t('label.url') + $t('message.character.limit', [200])"/>
            </template>
            <a-input
              v-model:value="formEdit.urlEdit"
              style="margin-top: 5px;"
              :placeholder="$t('label.url.tooltip')"/>
          </a-form-item>
          <a-form-item
            ref="urlImageEdit"
            name="urlImageEdit">
            <template #label>
              <tooltip-label :title="$t('label.url.image')" :tooltip="$t('label.url.image.tooltip') + ' ' + $t('label.url.image') + $t('message.character.limit', [200])"/>
            </template>
            <a-input
              v-model:value="formEdit.urlImageEdit"
              :placeholder="$t('label.url.image.tooltip')" />
          </a-form-item>
          <div v-if="formEdit.urlImageEdit !== undefined && formEdit.urlImageEdit !== '' && formEdit.urlImageEdit !== null">
            <a-form-item
              ref="urlImage2Edit"
              name="urlImage2Edit">
              <template #label>
                <tooltip-label :title="$t('label.url.image2')" :tooltip="$t('label.url.image2.tooltip') + ' ' + $t('label.url.image2') + $t('message.character.limit', [200])"/>
              </template>
              <a-input
                @change="changeUrlImage2Edit"
                v-model:value="formEdit.urlImage2Edit"
                :placeholder="$t('label.url.image2.tooltip')" />
            </a-form-item>
          </div>
          <div v-if="formEdit.urlImage2Edit !== undefined && formEdit.urlImage2Edit !== '' && formEdit.urlImage2Edit !== null">
            <a-form-item
              ref="urlImage3Edit"
              name="urlImage3Edit">
              <template #label>
                <tooltip-label :title="$t('label.url.image3')" :tooltip="$t('label.url.image3.tooltip') + ' ' + $t('label.url.image3') + $t('message.character.limit', [200])"/>
              </template>
              <a-input
                v-model:value="formEdit.urlImage3Edit"
                :placeholder="$t('label.url.image3.tooltip')" />
            </a-form-item>
          </div>
          <a-form-item ref="selectDistributorEdit" name="selectDistributorEdit">
            <template #label>
                <tooltip-label :title="$t('label.select.distributor')" :tooltip="$t('message.select.distributor.article')"/>
            </template>
            <a-select v-model:value="formEdit.selectDistributorEdit" :placeholder="$t('message.select.distributor.article')">
              <a-select-option v-for="distributor in distributorsList" :key="distributor.Uuid">
                  <span>{{ distributor.Name }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-spin>
      </a-form>
    </a-modal>
    <!-- Start of Table /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <a-card>
      <a-button
        :loading="loading"
        style="background: white"
        shape="round"
        size="small"
        @click="fetchData()">
        <template #icon><reload-outlined /></template>
        {{ $t('label.refresh') }}
      </a-button>
      <div style="display: flex; justify-content: flex-end; margin-bottom: 10px;">
        <div style="width: 300px; margin-right: 20px;">
        <a-input-search v-model:value="searchInput" placeholder="Suchtext..." @search="handleSearch(searchInput)" size="default" >
          </a-input-search>
        </div>
        <a-button id="addArticleBtn" type="primary" shape="round" @click="openAddArticle()">{{ $t('label.distributor.article.add') }}</a-button>
      </div>
      <a-table
        :columns="columns"
        :dataSource="articlesList"
        @refresh="fetchData"
        :rowKey="record => record.Uuid">
        <template #Url="{ record }">
          <a :href="record.Url" target="_blanc">{{ record.Url }}</a>
        </template>
        <template #Price="{ record }">
          <span v-if="record.Price.toString().split('.')[1] !== undefined && record.Price.toString().split('.')[1].length === 1">{{ record.Price.toString() + '0 €' }}</span>
          <span v-else>{{ record.Price + ' €' }}</span>
        </template>
        <template #options="{ record }">
          <div class="actions">
            <tooltip-button
              :tooltip="$t('label.distributor.article.delete')"
              tooltipPlacement="left"
              icon="delete-outlined"
              type="primary"
              danger
              @onClick="openDeleteModal(record.Description, record.Uuid, record.ArticleNumber)" />
            <tooltip-button
              :tooltip="$t('label.distributor.article.edit')"
              tooltipPlacement="right"
              icon="edit-outlined"
              type="default"
              @onClick="openEditModal(record)" />
          </div>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { genericCompare } from '@/utils/sort.js'
import api2 from '@/wpApi/api2'
import { reactive, ref, toRaw } from 'vue'
import TooltipButton from '@/components/widgets/TooltipButton'
import TooltipLabel from '@/components/widgets/TooltipLabel'
import { DeleteOutlined } from '@ant-design/icons-vue'

export default {
  name: 'DistributorArticles',
  components: {
    genericCompare,
    TooltipButton,
    TooltipLabel,
    DeleteOutlined
  },
  data () {
    return {
      distributorsList: [],
      editArticle: undefined,
      selectedToDeleteArticleNumber: undefined,
      selectedToDeleteName: undefined,
      selectedToDeleteUuid: undefined,
      openAddArticleModal: false,
      openEditArticleModal: false,
      openDeleteArticleModal: false,
      onlyNumbersLettersMinusRegex: /^[a-zA-Z0-9-]+$/,
      onlySemiCommaPipeRegex: /[,;|]+/,
      onlySemiPipeRegex: /[;|]+/,
      loggedInAccount: this.$store.getters.userInfo.accountid,
      loading: false,
      tableBackup: [],
      filteredList: [],
      articlesList: [],
      items: [],
      searchInput: '',
      columns: [
        {
          title: this.$t('label.article.number'),
          dataIndex: 'ArticleNumber',
          sorter: function (a, b) { return genericCompare(a[this.dataIndex] || '', b[this.dataIndex] || '') }
        },
        {
          title: this.$t('label.description'),
          dataIndex: 'Description',
          sorter: function (a, b) { return genericCompare(a[this.dataIndex] || '', b[this.dataIndex] || '') }
        },
        {
          title: this.$t('label.manufacturer'),
          dataIndex: 'Manufacturer',
          sorter: function (a, b) { return genericCompare(a[this.dataIndex] || '', b[this.dataIndex] || '') }
        },
        {
          title: this.$t('label.article.number') + ' (' + this.$t('label.manufacturer') + ')',
          dataIndex: 'ManufacturerNumber',
          sorter: function (a, b) { return genericCompare(a[this.dataIndex] || '', b[this.dataIndex] || '') }
        },
        {
          title: this.$t('label.productGroup'),
          dataIndex: 'ProductGroup',
          sorter: function (a, b) { return genericCompare(a[this.dataIndex] || '', b[this.dataIndex] || '') }
        },
        {
          title: this.$t('label.available'),
          dataIndex: 'Available'
        },
        {
          title: this.$t('label.price'),
          dataIndex: 'Price',
          slots: { customRender: 'Price' },
          width: '90px',
          sorter: function (a, b) { return genericCompare(a[this.dataIndex] || '', b[this.dataIndex] || '') }
        },
        {
          title: this.$t('label.url'),
          dataIndex: 'Url',
          slots: { customRender: 'Url' }
        },
        {
          title: this.$t('label.distributor'),
          dataIndex: 'DistributorName',
          sorter: function (a, b) { return genericCompare(a[this.dataIndex] || '', b[this.dataIndex] || '') }
        },
        {
          title: this.$t('label.options'),
          dataIndex: 'options',
          slots: { customRender: 'options' }
        }
      ]
    }
  },
  created () {
    this.initForm()
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({
        articleNumber: undefined,
        manufacturer: undefined,
        manufacturerNumber: undefined,
        description: undefined,
        detailedDescription: undefined,
        productGroup: undefined,
        available: undefined,
        price: undefined,
        url: undefined,
        selectDistributor: undefined,
        technicalDescriptionStructured: undefined,
        technicalDescriptionUnstructured: undefined,
        urlImage: undefined,
        urlImage2: undefined,
        urlImage3: undefined
      })
      this.rules = reactive({
        articleNumber: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateArticleNumber }
        ],
        manufacturer: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateManufacturer }
        ],
        manufacturerNumber: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateManufacturerNumber }
        ],
        productGroup: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateProductGroup }
        ],
        available: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateAvailable }
        ],
        price: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validatePrice }
        ],
        url: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateUrl }
        ],
        description: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateDescription }
        ],
        detailedDescription: [{ validator: this.validateDetailedDescription }],
        selectDistributor: [{ required: true, message: this.$t('message.error.required.input') }],
        technicalDescriptionStructured: [{ validator: this.validateTechnicalDescription }],
        technicalDescriptionUnstructured: [{ validator: this.validateTechnicalDescription }],
        urlImage: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateUrl }
        ],
        urlImage2: [{ validator: this.validateUrl }],
        urlImage3: [{ validator: this.validateUrl }]
      })
      this.formRefEdit = ref()
      this.formEdit = reactive({
        articleNumberEdit: undefined,
        manufacturerEdit: undefined,
        manufacturerNumberEdit: undefined,
        descriptionEdit: undefined,
        detailedDescriptionEdit: undefined,
        productGroupEdit: undefined,
        availableEdit: undefined,
        priceEdit: undefined,
        urlEdit: undefined,
        selectDistributorEdit: undefined,
        technicalDescriptionStructuredEdit: undefined,
        technicalDescriptionUnstructuredEdit: undefined,
        urlImageEdit: undefined,
        urlImage2Edit: undefined,
        urlImage3Edit: undefined
      })
      this.rulesEdit = reactive({
        articleNumberEdit: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateArticleNumberEdit }
        ],
        manufacturerEdit: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateManufacturer }
        ],
        manufacturerNumberEdit: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateManufacturerNumber }
        ],
        productGroupEdit: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateProductGroup }
        ],
        availableEdit: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateAvailable }
        ],
        priceEdit: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validatePrice }
        ],
        urlEdit: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateUrl }
        ],
        descriptionEdit: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateDescription }
        ],
        detailedDescriptionEdit: [{ validator: this.validateDetailedDescription }],
        selectDistributorEdit: [{ required: true, message: this.$t('message.error.required.input') }],
        technicalDescriptionStructuredEdit: [{ validator: this.validateTechnicalDescription }],
        technicalDescriptionUnstructuredEdit: [{ validator: this.validateTechnicalDescription }],
        urlImageEdit: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateUrl }
        ],
        urlImage2Edit: [{ validator: this.validateUrl }],
        urlImage3Edit: [{ validator: this.validateUrl }]
      })
    },
    changeUrlImage2 (val) {
      if (val.data === undefined || val.data === '' || val.data === null) {
        this.form.urlImage3 = undefined
      }
    },
    changeUrlImage2Edit (val) {
      if (val.data === undefined || val.data === '' || val.data === null) {
        this.formEdit.urlImage3Edit = undefined
      }
    },
    handleSearch (input) {
      this.filteredList = this.tableBackup.filter(function (article) {
        return article.ArticleNumber.toString().toLowerCase().includes(input.toString().toLowerCase()) ||
        article.Description.toString().toLowerCase().includes(input.toString().toLowerCase()) ||
        article.Manufacturer.toString().toLowerCase().includes(input.toString().toLowerCase()) ||
        article.ManufacturerNumber.toString().toLowerCase().includes(input.toString().toLowerCase()) ||
        article.ProductGroup.toString().toLowerCase().includes(input.toString().toLowerCase()) ||
        article.Price.toString().toLowerCase().includes(input.toString().toLowerCase()) ||
        article.DistributorName.toString().toLowerCase().includes(input.toString().toLowerCase())
      })
      this.current = 1
      this.articlesList = this.filteredList
      if (this.searchInput === '') {
        this.articleList = this.tableBackup
      }
    },
    openAddArticle () {
      this.fetchDistributors()
      this.openAddArticleModal = true
    },
    openEditModal (record) {
      this.fetchDistributors()
      this.formEdit.articleNumberEdit = record.ArticleNumber === null ? undefined : record.ArticleNumber
      this.formEdit.manufacturerEdit = record.Manufacturer === null ? undefined : record.Manufacturer
      this.formEdit.manufacturerNumberEdit = record.ManufacturerNumber === null ? undefined : record.ManufacturerNumber
      this.formEdit.descriptionEdit = record.Description === null ? undefined : record.Description
      this.formEdit.detailedDescriptionEdit = record.AdditionalDescription === null ? undefined : record.AdditionalDescription
      this.formEdit.productGroupEdit = record.ProductGroup === null ? undefined : record.ProductGroup
      this.formEdit.availableEdit = record.Available === null ? undefined : record.Available
      this.formEdit.priceEdit = record.Price === null ? undefined : record.Price
      this.formEdit.urlEdit = record.Url === null ? undefined : record.Url
      this.formEdit.technicalDescriptionStructuredEdit = record.TechnicalDescriptionStructured === null ? undefined : record.TechnicalDescriptionStructured
      this.formEdit.technicalDescriptionUnstructuredEdit = record.TechnicalDescription === null ? undefined : record.TechnicalDescription
      this.formEdit.urlImageEdit = record.UrlImage === null ? undefined : record.UrlImage
      this.formEdit.urlImage2Edit = record.UrlImage2 === null ? undefined : record.UrlImage2
      this.formEdit.urlImage3Edit = record.UrlImage3 === null ? undefined : record.UrlImage3
      this.formEdit.selectDistributorEdit = record.DistributorUuid
      this.editArticle = record.Uuid
      this.openEditArticleModal = true
    },
    openDeleteModal (articleDescription, articleUuid, articleNumber) {
      this.selectedToDeleteName = articleDescription
      this.selectedToDeleteUuid = articleUuid
      this.selectedToDeleteArticleNumber = articleNumber
      this.openDeleteArticleModal = true
    },
    async deleteArticle () {
      if (this.selectedToDeleteUuid !== undefined) {
        var deleteParams = new FormData()
        deleteParams.append('uuid', this.selectedToDeleteUuid)
        deleteParams.append('articleNumber', this.selectedToDeleteArticleNumber)
        await api2.deleteArticle(deleteParams).then(response => {
          this.$notification.success({
            message: this.$t('label.success'),
            description: this.$t('message.success.delete.article')
          })
          this.selectedToDeleteName = undefined
          this.selectedToDeleteUuid = undefined
          this.closeAction()
        }).catch(error => {
          this.selectedToDeleteName = undefined
          this.selectedToDeleteUuid = undefined
          this.closeAction()
          this.$notification.error({
            message: this.$t('label.error'),
            description: error.response.data,
            duration: 0
          })
        })
        this.fetchData()
      }
    },
    handleSearchReset () {
      this.searchInput = ''
      this.articlesList = this.tableBackup
    },
    async validateArticleNumber (rule, value) {
      const errorMessage = this.$t('label.article.number') + this.$t('message.character.limit', ['25'])
      const errorMessage2 = this.$t('message.allow.only.numbers.letters.minus')
      const errorMessage3 = this.$t('message.error.articleNumber')
      if (value === undefined || value.toString().length < 26) {
        if (this.onlyNumbersLettersMinusRegex.test(value) === false) {
          return Promise.reject(errorMessage2)
        }
        if (this.articlesList.filter(article => article.ArticleNumber === value)[0]) {
          return Promise.reject(errorMessage3)
        }
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async validateArticleNumberEdit (rule, value) {
      const errorMessage = this.$t('label.article.number') + this.$t('message.character.limit', ['25'])
      const errorMessage2 = this.$t('message.allow.only.numbers.letters.minus')
      if (value === undefined || value.toString().length < 26) {
        if (this.onlyNumbersLettersMinusRegex.test(value) === false) {
          return Promise.reject(errorMessage2)
        }
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async validateManufacturer (rule, value) {
      const errorMessage = this.$t('label.manufacturer') + this.$t('message.character.limit', ['35'])
      if (value === undefined || value.length < 36) {
        if (this.onlySemiCommaPipeRegex.test(value) === true) {
          return Promise.reject(this.$t('message.dont.allow.semi.comma.pipe'))
        }
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async validateManufacturerNumber (rule, value) {
      const errorMessage = this.$t('label.manufacturer') + '(' + this.$t('label.manufacturer') + ')' + this.$t('message.character.limit', ['50'])
      if (value === undefined || value.length < 51) {
        if (this.onlySemiCommaPipeRegex.test(value) === true) {
          return Promise.reject(this.$t('message.dont.allow.semi.comma.pipe'))
        }
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async validateDescription (rule, value) {
      const errorMessage = this.$t('label.article.description') + this.$t('message.character.limit', ['100'])
      if (value === undefined || value.length < 101) {
        if (this.onlySemiCommaPipeRegex.test(value) === true) {
          return Promise.reject(this.$t('message.dont.allow.semi.comma.pipe'))
        }
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async validateDetailedDescription (rule, value) {
      const errorMessage = this.$t('label.article.detailedDescription') + this.$t('message.character.limit', ['250'])
      if (value === undefined || value === null || value.length < 251) {
        if (this.onlySemiPipeRegex.test(value) === true) {
          return Promise.reject(this.$t('message.dont.allow.semi.comma.pipe'))
        }
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async validateProductGroup (rule, value) {
      const errorMessage = this.$t('label.productGroup') + this.$t('message.character.limit', ['200'])
      if (value === undefined || value.length < 201) {
        if (this.onlySemiCommaPipeRegex.test(value) === true) {
          return Promise.reject(this.$t('message.dont.allow.semi.comma.pipe'))
        }
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async validateAvailable (rule, value) {
      const errorMessage = this.$t('label.available') + this.$t('message.character.limit', ['12'])
      const errorMessage2 = this.$t('message.no.decimals')
      if (value === undefined || value.toString().length < 13) {
        if (value.toString().includes('.') || value.toString().includes(',')) {
          return Promise.reject(errorMessage2)
        }
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async validatePrice (rule, value) {
      var compareString = value.toString()
      if (value.toString().includes('.')) {
        compareString = value.toString().split('.')[0]
      }
      const errorMessage = this.$t('label.price') + this.$t('message.character.limit', ['12'])
      if (value === undefined || compareString.length < 13) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async validateTechnicalDescription (rule, value) {
      const errorMessage = this.$t('label.label.technicalDescription') + this.$t('message.character.limit', ['2000'])
      if (value === undefined || value.length < 2001) {
        if (this.onlySemiPipeRegex.test(value) === true) {
          return Promise.reject(this.$t('message.dont.allow.semi.comma.pipe'))
        }
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async validateUrl (rule, value) {
      const errorMessage = this.$t('label.url') + this.$t('message.character.limit', ['200'])
      if (value === undefined || value.length < 201) {
        if (this.onlySemiCommaPipeRegex.test(value) === true) {
          return Promise.reject(this.$t('message.dont.allow.semi.comma.pipe'))
        }
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async fetchData () {
      this.loading = true
      await this.fetchArticles()
      this.loading = false
    },
    async fetchArticles () {
      this.loading = true
      await api2.listAllArticles().then(response => {
        this.articlesList = response.data
        this.tableBackup = response.data
        this.loading = false
      }).catch(error => {
        this.$notifyError(error.response.data)
        this.loading = false
      })
    },
    async fetchDistributors () {
      this.loading = true
      await api2.listDistributors().then(response => {
        this.distributorsList = response.data
        this.loading = false
      }).catch(error => {
        this.$notifyError(error.response.data)
        this.loading = false
      })
    },
    closeAction () {
      this.openAddArticleModal = false
      this.openDeleteArticleModal = false
      this.openEditArticleModal = false
      this.formEdit.articleNumberEdit = undefined
      this.formEdit.manufacturerEdit = undefined
      this.formEdit.manufacturerNumberEdit = undefined
      this.formEdit.descriptionEdit = undefined
      this.formEdit.detailedDescriptionEdit = undefined
      this.formEdit.productGroupEdit = undefined
      this.formEdit.availableEdit = undefined
      this.formEdit.priceEdit = undefined
      this.formEdit.urlEdit = undefined
      this.formEdit.selectDistributorEdit = undefined
      this.form.articleNumber = undefined
      this.form.manufacturer = undefined
      this.form.manufacturerNumber = undefined
      this.form.description = undefined
      this.form.productGroup = undefined
      this.form.available = undefined
      this.form.price = undefined
      this.form.url = undefined
      this.form.selectDistributor = undefined

      this.editArticle = undefined
      this.loading = false
    },
    submitAddArticle (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRef.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.form)
        var params = new FormData()
        params.append('articleNumber', values.articleNumber)
        params.append('manufacturer', values.manufacturer)
        params.append('manufacturerNumber', values.manufacturerNumber)
        params.append('description', values.description)
        params.append('additionalDescription', values.detailedDescription === undefined ? '' : values.detailedDescription)
        params.append('productGroup', values.productGroup)
        params.append('available', values.available)
        params.append('price', values.price)
        params.append('url', values.url)
        params.append('technicalDescription', values.technicalDescriptionUnstructured === undefined ? '' : values.technicalDescriptionUnstructured)
        params.append('technicalDescriptionStructured', values.technicalDescriptionStructured === undefined ? '' : values.technicalDescriptionStructured)
        params.append('urlImage', values.urlImage === undefined ? '' : values.urlImage)
        params.append('urlImage2', values.urlImage2 === undefined ? '' : values.urlImage2)
        params.append('urlImage3', values.urlImage3 === undefined ? '' : values.urlImage3)
        params.append('distributorUuid', values.selectDistributor)

        await api2.createArticle(params).then(response => {
          console.log('Article created')
          this.loading = false
          this.$notification.success({
            message: this.$t('label.success'),
            description: this.$t('message.success.create.article')
          })
          this.closeAction()
        }).catch(error => {
          this.$notification.error({
            message: this.$t('label.error'),
            description: error.response.data,
            duration: 0
          })
          this.closeAction()
        }).finally(() => {
          this.fetchData()
        })
      })
    },
    submitEditArticle (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRefEdit.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.formEdit)

        var params = new FormData()
        params.append('uuid', this.editArticle)
        params.append('articleNumber', values.articleNumberEdit)
        params.append('manufacturer', values.manufacturerEdit)
        params.append('manufacturerNumber', values.manufacturerNumberEdit)
        params.append('description', values.descriptionEdit)
        params.append('additionalDescription', values.detailedDescriptionEdit === undefined ? '' : values.detailedDescriptionEdit)
        params.append('productGroup', values.productGroupEdit)
        params.append('available', values.availableEdit)
        params.append('price', values.priceEdit)
        params.append('url', values.urlEdit)
        params.append('technicalDescription', values.technicalDescriptionUnstructuredEdit === undefined ? '' : values.technicalDescriptionUnstructuredEdit)
        params.append('technicalDescriptionStructured', values.technicalDescriptionStructuredEdit === undefined ? '' : values.technicalDescriptionStructuredEdit)
        params.append('urlImage', values.urlImageEdit === undefined ? '' : values.urlImageEdit)
        params.append('urlImage2', values.urlImage2Edit === undefined ? '' : values.urlImage2Edit)
        params.append('urlImage3', values.urlImage3Edit === undefined ? '' : values.urlImage3Edit)
        params.append('distributorUuid', values.selectDistributorEdit)
        await api2.editArticle(params).then(response => {
          console.log('Article edited')
          this.loading = false
          this.$notification.success({
            message: this.$t('label.success'),
            description: this.$t('message.success.edit.article')
          })
          this.closeAction()
        }).catch(error => {
          this.$notification.error({
            message: this.$t('label.error'),
            description: error.response.data,
            duration: 0
          })
          this.closeAction()
        }).finally(() => {
          this.fetchData()
        })
      })
    }
  }
}
</script>

<style scoped>
  .table-tooltip-icon {
    color: rgba(0,0,0,.45);
    margin-left: 5px;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    width: 70px;
  }
  .ant-btn-round {
    background: #3D7CAF;
  }
</style>
